<script>
    import Layout from '../../../layouts/main'
    import PageHeader from '@/components/page-header'

    import { urlBackend } from '../../../../helpers/global';
    import axiosAdmin from '../../../../api/axiosAdmin';
    
    import { required } from "vuelidate/lib/validators";

    export default {
        components: { Layout, PageHeader },
        data() {
            return {
                title : 'Simulation',
                items: [
                    {
                        text: "Payment",
                    },
                    {
                        text: "Simulation",
                        href: "/ss/admin/payment/simulation",
                        active: true,
                    },
                ],
                searched : false,
                loadingBilling : false,
                idBilling : "",
                billing : {},

                loadingSimulate : false,
            }
        },
        validations : {
            idBilling : {
                required
            }
        },
        methods : {
            async searchBilling() {
                try {
                    this.searched = true;
                    this.$v.$touch();
                    if (this.$v.$invalid) return false;
                    this.searched = 1;

                    let params = {
                        id_billing : this.idBilling
                    }
                    this.loadingBilling = true;
                    let response = await axiosAdmin.get(urlBackend + '/api/v1/billing', {params : params});
                    this.billing = response?.data?.results ?? {};
                } catch (err) {
                    this.$swal({
                        icon : 'error',
                        title : 'Sorry!',
                        text : err?.response?.data?.message ?? 'Something Wrong'
                    });
                } finally {
                    this.loadingBilling = false;
                }
            },
            statusBadgeClass : (status) => {
                let stackClass = {'pending' : 'bg-primary', 'settlement' : 'bg-success', 'expire' : 'bg-danger'};

                let classSelected = stackClass[status?.transaction_status] ?? 'bg-default';

                return [classSelected];
            },
            
            imgChannel : (channel) => {
                
                let images = require.context('@/assets/images/channel-bayar/', false, /\.png$/)

                let stackImg = {
                    'channel_bca.png' : ['bca_va'],
                    'channel_bjatim.png' : ['bjatim_va'],
                    'channel_bni.png' : ['bni_va', 'bni_va_doku', 'bni_va_mcp', 'bni_va_doku_snap'],
                    'channel_bsi.png' : ['bsi_va_doku', 'bsi_va_doku_snap'],
                    'channel_cimb.png' : ['cimb_va_doku', 'cimb_va_mcp', 'cimb_va_doku_snap'],
                    'channel_bri.png' : ['bri_va', 'bri_va_doku', 'bri_va_mcp', 'bri_va_doku_snap'],
                    'channel_permata.png' : ['permata_va', 'permata_va_doku', 'permata_va_mcp', 'permata_va_doku_snap'],
                    'channel_mandiri.png' : ['mandiri_va', 'mandiri_va_doku', 'echannel_mcp', 'mandiri_va_doku_snap'],
                    'channel_ovo.png' : ['ovo_mcp', 'ovo_doku'],
                    'channel_qris.png' : ['qris_shopeepay_mcp', 'qris_doku'],
                    'channel_shopeepay.png' : ['shopeepay_mcp'],
                    'channel_alfamart.png' : ['alfamart_mcp', 'alfamart_doku'],
                    'channel_pos.png' : ['pos_mcp'],
                    'channel_muamalat.png' : ['muamalat_va_mcp'],
                    'channel_indomaret.png' : ['indomaret'],
                    'channel_danamon.png' : ['danamon_va_doku', 'danamon_va_doku_snap'],
                    'channel_doku.png' : ['doku_va_doku', 'doku_va_doku_snap'],
                }

                let img = null;
                for(let nameImg in stackImg){
                    let list = stackImg[nameImg];
                    
                    if(list.indexOf(channel) > "-1"){
                        img = nameImg;
                        break;
                    }
                }
                if(!img) return false;

                return images('./' + img);
            },
            
            async simulateBilling() {
                try {
                    if(!this.billing?.order_id) throw { message : 'Missing Billing' }
                    const confirmAction = await this.$swal({
                        title: 'Your actions will make this bill pay off.<br/><br/>Are you sure?',
                        showDenyButton: true,
                        confirmButtonText: 'Yes',
                        denyButtonText: `No`,
                        allowOutsideClick: false
                    })
                    if(confirmAction.isDenied == true) return false
                    this.loadingSimulate = true;
                    let request = {
                        id_billing : this.billing.order_id
                    }

                    await axiosAdmin.post(urlBackend + '/api/v1/simulation', request);
                    
                    let params = {
                        id_billing : this.billing.order_id
                    }
                    this.loadingBilling = true;
                    let response = await axiosAdmin.get(urlBackend + '/api/v1/billing', {params : params});
                    this.billing = response?.data?.results ?? {};

                    this.loadingSimulate = false;

                } catch (err) {
                    this.$swal({
                        icon : 'error',
                        title : 'Sorry!',
                        text : err?.response?.data?.message ?? err?.message ?? 'Something Wrong'
                    });
                } finally {
                    this.loadingSimulate = false;
                    this.loadingBilling = false;
                }
            }
        },

        created() {
            let params = this.$route.query;
            if(params?.id_billing) {
                this.idBilling = params?.id_billing;
                this.searchBilling();
            } 
        }
    }
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-6 col-sm-8 col-12">
                <div class="card">
                    <b-form @submit.prevent="searchBilling()">

                    <div class="card-body">
                        <h4 class="card-title mb-4">Search Billing</h4>

                        <b-form-group
                            class="mb-3"
                            label="ID Billing"
                            label-for="horizontal-firstname-input"
                            label-cols="12"
                            label-cols-sm="4"
                            content-cols-sm="8"
                            content-cols="12"
                        >
                            <b-form-input
                                id="horizontal-firstname-input"
                                type="text"
                                v-model="idBilling"
                                placeholder="Enter ID Billing"
                                :class="{ 'is-invalid': searched && $v.idBilling.$error }"
                            ></b-form-input>
                            <div
                                v-if="searched && $v.idBilling.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.idBilling.required">ID Billing is required.</span>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-6 col-0"></div>
                            <b-button type="submit" variant="primary" class="col-sm-6 col-12" 
                                :disabled="loadingBilling"
                                >
                                <b-spinner small type="grow" v-if="loadingBilling"></b-spinner>
                                <i class="bx bx-search-alt-2 font-size-16 align-middle me-2" v-else></i>
                                Search
                            </b-button>
                        </div>
                    </div>
                    </b-form>

                </div>
            </div>
        </div>

        <div class="row" v-if="searched === 1">
            <div class="col-sm-9 col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-4">Billing</h4>
                        <div class="text-center" v-if="loadingBilling">
                            <b-spinner
                                class="m-2"
                                variant="primary"
                                role="status"
                            ></b-spinner>
                        </div>
                        
                        <p v-if="!billing.hasOwnProperty('id')" class="text-center"> Billing Not Found. </p>
                        <div v-else>
                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Name</p>
                                <p class="col-12 col-sm-6 fw-bold">{{billing.nama_siswa}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Order ID</p>
                                <p class="col-12 col-sm-6 fw-bold">{{billing.order_id}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Transaction ID</p>
                                <p class="col-12 col-sm-6 fw-bold">{{billing.transaction_id}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Status</p>
                                <p class="col-12 col-sm-6">
                                    <span class="badge rounded-pill p-2 fs-6 fw-bold" :class="statusBadgeClass(billing)">{{billing.transaction_status}}</span>
                                </p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Departement Code</p>
                                <p class="col-12 col-sm-6 fw-bold">{{billing.dep_kode}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Time</p>
                                <p class="col-12 col-sm-6 fw-bold">{{$root.humanDate(billing.created_at)}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Payment</p>
                                <p class="col-12 col-sm-6 fw-bold">
                                    <img :src="imgChannel(billing.inquiry_data.channel)" v-if="imgChannel(billing.inquiry_data.channel)" alt height="44" />
                                    <span v-else>{{billing.inquiry_data.channel}}</span>
                                    <!-- <img src="@/assets/images/channel-bayar/channel_bsi.png" alt height="44" /> -->
                                </p>
                            </div>

                            <div class="col-12 row mb-3" v-if="billing.inquiry_data.code_biller">
                                <p class="col-12 col-sm-6">Code Biller</p>
                                <p class="col-12 col-sm-6 fw-bold">{{billing.inquiry_data.code_biller}}</p>
                            </div>

                            <div class="col-12 row mb-3" v-if="billing.inquiry_data.va_number">
                                <p class="col-12 col-sm-6">Virtual Account</p>
                                <p class="col-12 col-sm-6 fw-bold">{{billing.inquiry_data.va_number}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Amount</p>
                                <p class="col-12 col-sm-6 fw-bold">{{$root.rupiah(billing.nominal_pokok)}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Service Fee</p>
                                <p class="col-12 col-sm-6 fw-bold">{{$root.rupiah( parseInt(billing.gross_amount) - parseInt(billing.nominal_pokok) )}}</p>
                            </div>

                            <div class="col-12 row mb-3">
                                <p class="col-12 col-sm-6">Gross Amount</p>
                                <p class="col-12 col-sm-6 fw-bold">{{$root.rupiah(billing.gross_amount)}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-sm-6 col-md-9 col-0"></div>
                            <b-button v-if="['pending', 'expire'].indexOf(billing.transaction_status) > '-1'" type="button" variant="success" class="col-sm-6 col-md-3 col-12" @click="simulateBilling()" 
                                :disabled="loadingSimulate"
                                >
                                <b-spinner small type="grow" v-if="loadingSimulate"></b-spinner>
                                <i class="bx bxs-wallet-alt font-size-16 align-middle me-2" v-else></i>
                                Pay
                            </b-button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </Layout>
</template>
